import axios from 'axios';
import {retrieveAccessToken} from './storage';

export const BACKEND_URI =
  process.env.NEXT_PUBLIC_BACKEND_URI || 'http://localhost:8080';

export const http = axios.create({baseURL: BACKEND_URI});

http.interceptors.request.use(async (config: any) => {
  const token = await retrieveAccessToken();

  if (token) {
    config.headers = config.headers || {};
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

//user
export const checkEmail = async (email: string): Promise<void> => {
  const res = await http.post('/api/user/check-email', {email});
  return res.data;
};

export const register = async (data: any): Promise<{token: string}> => {
  const res = await http.post('/api/user/registration', data);
  return res.data;
};

export const login = async (data: {
  username: string;
  password: string;
}): Promise<{token: string}> => {
  const res = await http.post('/api/login', data);
  return res.data;
};

export const fetchUser = async (): Promise<User> => {
  const res = await http.get('/api/user/me');
  return res.data;
};

export const passwordRecovery = async (
  email: User['email']
): Promise<{session: Session}> => {
  const res = await http.post('/api/user/request-recover-password', {
    email,
  });
  return res.data;
};

export const passwordResetToken = async (
  token: string,
  email: string
): Promise<{session: Session}> => {
  const res = await http.post('/api/user/recover-password-verification-token', {
    token,
    email,
  });
  return res.data;
};

export const passwordReset = async (
  token: string,
  email: string,
  password: string
): Promise<{session: Session}> => {
  const res = await http.post('/api/user/recover-to-change-password', {
    token,
    email,
    password,
  });
  return res.data;
};

export const editProfile = async (
  body: ProfileFormValues
): Promise<{message: string}> => {
  const res = await http.post('/api/user/edit', body);

  return res.data;
};

// Popup
export const savePopupMdcodes = async (): Promise<{message: string}> => {
  const response = await http.post('/api/user/popup/mdcodes');

  return response.data;
};

export const savePopupFormula = async (): Promise<{message: string}> => {
  const response = await http.post('/api/user/popup/formula');

  return response.data;
};

//specializations
export const fetchSpecializations = async (): Promise<Specialization[]> => {
  const res = await http.get('/api/user/specialization');
  return res.data;
};

//curricula
export const fetchCurricula = async (): Promise<Curriculum[]> => {
  const res = await http.get('/api/curricula');
  return res.data;
};

export const fetchCurriculum = async (
  curriculumId: Curriculum['id']
): Promise<CurriculumWithCourses> => {
  const res = await http.get(`/api/curriculum/${curriculumId}/details`);
  return res.data;
};

//courses
export const fetchCourse = async (
  courseId: Course['courseId']
): Promise<CourseDetails> => {
  const res = await http.get(`/api/courses/${courseId}/details`);

  return res.data;
};

//sessions
export const sessionEnroll = async (
  sessionId: Session['id'],
  termsAccepted: boolean,
  patient: boolean,
  allergies: string
): Promise<{session: Session}> => {
  const res = await http.post(`/api/sessions/${sessionId}/enroll`, {
    termsAccepted,
    patient,
    allergies,
  });
  return res.data;
};

export const sessionCancel = async (
  sessionId: Session['id']
): Promise<{session: Session}> => {
  const res = await http.post(`/api/sessions/${sessionId}/cancel`);
  return res.data;
};

export const getSignFormURL = async (
  sessionId: string
): Promise<{status: 'OUT_FOR_SIGNATURE' | 'SIGNED'; url: string}> => {
  const res = await http.post(`/api/sessions/${sessionId}/sign-document`);

  return res.data;
};

//teachers
export const fetchTeachers = async (): Promise<[Teacher]> => {
  const res = await http.get('/api/teachers');

  return res.data;
};

//contents
export const fetchContents = async (
  text: string,
  categories: string[],
  facezones: string[],
  drugs: string[]
): Promise<Content[]> => {
  const res = await http.post('/api/learning-area/articles', {
    text,
    categories,
    facezones,
    drugs,
  });
  return res.data;
};

export const fetchContent = async (
  canonical: Content['canonical']
): Promise<Content> => {
  const res = await http.get(
    `/api/learning-area/article/canonical/${canonical}`
  );
  return res.data;
};

export const fetchContentsCategories = async (): Promise<ContentCategory[]> => {
  const res = await http.get(`/api/learning-area/categories`);
  return res.data;
};

export const fetchContentsDrugs = async (): Promise<ContentDrug[]> => {
  const res = await http.get(`/api/learning-area/drugs`);

  return res.data;
};

export const fetchContentsFeatured = async (): Promise<{
  featured: Content[];
  suggest: Content[];
}> => {
  const res = await http.get(`/api/learning-area/featured`);

  return res.data;
};

export const saveRating = async (
  id: string,
  rating: number
): Promise<{idFeedback: string}> => {
  const res = await http.post(`/api/learning-area/article/${id}/star`, {
    rating,
  });

  return res.data;
};

export const saveFeedback = async (
  id: string,
  idFeedback: string,
  feedback?: string
): Promise<{
  valid: string;
}> => {
  const res = await http.post(
    `/api/learning-area/article/${id}/feedback/${idFeedback}`,
    {
      ...(feedback && {feedback}),
    }
  );

  return res.data;
};

// Admin
export const fetchPreview = async (id: string): Promise<Content> => {
  const res = await http.get(`/admin/api/learning-area/article/${id}`, {
    validateStatus: function (status) {
      return status < 299;
    },
  });

  return res.data;
};
